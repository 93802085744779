//global
$font-color: #1d1d1d;

//custom
$color-red: #ff0000;
$color-green: #00ff00;
$color-blue: #0000ff;
$color-grey: #f8f9fa;
$color-content: white;
$dark-violet: #5e35b1;
$light-grayish-violet: #ede7f6;
$cyan-blue: #e3f2fd;
$white: rgb(255, 255, 255);
$strong-blue: #1976d2;
$very-dark-gray: #212121;
$light-grey: #d3d3d3;
$search-background: #fafafa;
$input-border-color: #7367f0;
$input-border-invalid-color: #ea5455;
$container-white-background: rgb(255, 255, 255);
$placeholder-color: #ccc;
$separator-background: #f7fafe;
$separator-color: #10559a;
$form-caption-color: rgb(103, 119, 136);
$curved-background: #f7faff;
//Header color scss : Please refer for colors in Header
$dark-grey: #333333;
$medium-grey: #636363;
$light-grey: #eeeeee;
$ash: #f4f4f4;
$primary-color: #2b72fb;
$white: white;
$header-accordion-btn: #f7fafe;
$border: 1px solid #eeeeee;
$shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
  rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
$light-pink: #fff8f9;
// general blue button
$button-bg: rgb(55, 125, 255);
$button-bg-color: #0f559a;
$load-button-color: #ffe6e5;
$disable-button: #cccccc;
$disable-button-border: #999999;
$disable-button-text-color: #666666;
// ship-card
$ship-card-heading: #12569d;

$app-background: #e8ecf0;

// Editor css & color
$editor-bg: #f9fbfd;
$editor-border-color: #c7c7c7;
$input-border-color: rgba(0, 0, 0, 0.12);
$placeholder-text-color: #cbd5e0;
$published-status-color: #2e7d32;
$draft-status-color: #1976d2;
$deleted-status-color: #ff0000;
$comment-heading-bg:#ced9e1;

// Sidebar
/* ===== Colors ===== */
$sidebar-color: #fff;
$primary-color: #2c2c2c;
$primary-color-light: #f6f5ff;
$toggle-color: #ddd;
$text-color: #0d0138;

/* ====== Transition ====== */
$tran-03: all 0.2s ease;
$tran-03: all 0.3s ease;
$tran-04: all 0.3s ease;
$tran-05: all 0.5s ease;

// Footer Text color
$footer-text: #0d0138;

// Table
$table-header: #000000;

// material ui font family
$mui-font-family: "Poppins", sans-serif;

/* ===== Spacing ===== */

// Base spacing unit (minimum spacing value)
$spacing-base: 8px;

// Use this small spacing for tight spaces between elements like icons, small buttons, or compact form elements.
$spacing-small: $spacing-base * 1;

// Use this medium spacing for general spacing between elements like paragraphs, buttons, and form inputs.
$spacing-medium: $spacing-base * 2;

// Use this large spacing for creating more significant separations between sections or major elements.
$spacing-large: $spacing-base * 3;

// Use this extra-large spacing for creating large gaps between major sections or elements to add emphasis or visual breathing room.
$spacing-xlarge: $spacing-base * 4;
