@import "/src/styles/variables";

.shared-top-header-container {
  width: 100%;
  display: flex;
  .left-shared-section {
    width: 20%;
  }
  .center-shared-section-mobile {
    display: none;
  }
  .center-shared-section {
    width: 58%;
    display: flex;
    justify-content: space-between;
    .doc-with-status {
      display: flex;
      align-items: center;
      width: 100%;
      .doc-name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding-left: 10px;
      }
    }
    .download-button {
      // width: 15%;
      padding-left: 1rem;
      padding-right: 1rem;
      height: 40px;
      margin-top: 10px;
      margin-left: 1rem;
    }
  }
  .right-shared-section {
    width: 21%;
  }
}

@media (max-width: 768px) {
  .shared-top-header-container {
    .center-shared-section {
      width: 100%;
    }
  }
}

@media (max-width: 450px) {
  .shared-top-header-container {
    background-color: $app-background;
    padding-bottom: 1rem;
    padding-top: 1rem;
    .center-shared-section {
      display: none;
    }
    .center-shared-section-mobile {
      padding-top: 5rem;
      width: 100vw;
      z-index: 2000;
      background-color: $app-background;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      .doc-with-status {
        display: flex;
        align-items: center;
        width: 100%;
        .doc-name {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          padding-left: 10px;
        }
      }
      .download-button {
        // width: 15%;
        padding-left: 1rem;
        padding-right: 1rem;
        height: 40px;
        margin-top: 10px;
        margin-left: 1rem;
      }
    }
  }
}
