@import "./styles/variables";

:root {
  color: $font-color;

  .css-1hdyuqk-MuiPaper-root-MuiAlert-root {
    font-family: "Poppins", sans-serif !important;
  }
}

hr {
  display: block;
  height: 1px;
  border: 0;
  padding: 0 !important;
  background-color: $placeholder-text-color;
}
