@import "src/styles/variables";

.card-container {
  flex: 10 0 200px;
  height: 175px;
  width: 400px;
  max-width: 400px;
  border-radius: 10px;
  background-color: $white;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    padding: 50%;
    width: 32px;
    height: 32px;
    background-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
    transition: opacity 1s, transform 0.5s;
  }
  &:active::after {
    opacity: 0.32;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0s;
  }
  &:hover {
    background-color: rgba(0, 149, 128, 0.47);
  }
  .card-header {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-top: 11px;
    color: #0d0138;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    .right-icon {
    }
  }
  .card-title {
    color: #0d0138;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.176px;
    padding-top: 60px;
    padding-left: 10px;
  }
  .card-footer {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 21px;
    color: #0d0138;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }
}
.active-card {
  background-color: rgba(0, 149, 128, 0.47);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.79);
  border: 1px solid #009580;
}
