@import "src/styles/variables";

/* ===== Sidebar ===== */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  padding: 10px 14px;
  background: $app-background;
  transition: $tran-05;
  z-index: 100;
  border: 1px solid white;
}
.sidebar.close {
  width: 88px;
}

/* ===== Reusable code - Here ===== */
.sidebar li {
  height: 50px;
  list-style: none;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.sidebar header .image,
.sidebar .icon {
  min-width: 60px;
  border-radius: 6px;
}

.sidebar .icon {
  min-width: 60px;
  border-radius: 6px;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px !important;
}

// .sidebar .text,
// .sidebar .icon {
//   color: $text-color;
//   transition: $tran-03;
// }

.left-section-text-color {
  color: $text-color;
}

.sidebar .text {
  font-size: 16px !important;
  font-weight: 500;
  white-space: nowrap;
  opacity: 1;
}
.sidebar.close .text {
  opacity: 0;
}
/* =========================== */

.sidebar header {
  position: relative;
}

.sidebar header .image-text {
  display: flex;
  align-items: center;
}
.sidebar header .logo-text {
  display: flex;
  flex-direction: column;
}
header .image-text .name {
  margin-top: 2px;
  font-size: 18px;
  font-weight: 600;
}

header .image-text .profession {
  font-size: 16px;
  margin-top: -2px;
  display: block;
}

.sidebar header .image {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: $tran-05;
}

.sidebar header .image img {
  border-radius: 6px;
}

.img-margin {
  margin-left: 18px;
}

.sidebar header .toggle {
  position: absolute;
  top: 22rem;
  right: -25px;
  transform: translateY(-50%) rotate(180deg);
  height: 25px;
  width: 25px;
  background-color: $strong-blue;
  color: $sidebar-color;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: $tran-05;
}

.sidebar.close .toggle {
  transform: translateY(-50%) rotate(0deg);
}

.sidebar .menu {
  margin-top: 40px;
}

.sidebar li.search-box {
  border-radius: 6px;
  background-color: $primary-color-light;
  cursor: pointer;
  transition: $tran-05;
}

.sidebar li a {
  list-style: none;
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  text-decoration: none;
  transition: $tran-03;
  position: relative;
  overflow: hidden;
}
.sidebar li a::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  padding: 50%;
  width: 32px;
  height: 32px;
  background-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
  opacity: 0;
  transform: translate(-50%, -50%) scale(1);
  transition:
    opacity 1s,
    transform 0.5s;
}
.sidebar li a:active:after {
  opacity: 0.32;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0s;
}
.sidebar li a:hover {
  background-color: $primary-color;
}

.sidebar li a:hover .icon,
.sidebar li a:hover .text {
  color: $sidebar-color;
}

.selected-link {
  color: $sidebar-color !important;
  background-color: $primary-color !important;
}

// .sidebar li a .icon .text .selected-link {
//   background-color: $primary-color !important;
//   color: $sidebar-color !important;
// }

.sidebar .menu-bar {
  height: calc(100% - 75px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
}
.menu-bar::-webkit-scrollbar {
  display: none;
}

.menu-bar .bottom-content .toggle-switch {
  position: absolute;
  right: 0;
  height: 100%;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
}

.toggle-switch .switch {
  position: relative;
  height: 22px;
  width: 40px;
  border-radius: 25px;
  background-color: $toggle-color;
  transition: $tran-05;
}

.switch::before {
  content: "";
  position: absolute;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  background-color: $sidebar-color;
  transition: $tran-04;
}

.sidebar.close ~ .home {
  left: 78px;
  width: calc(100% - 78px);
}
.mobile-view-logo {
  display: none !important;
}
@media only screen and (max-width: 780px) {
  .sidebar {
    display: none;
  }
  .sidebar.close ~ .home {
    left: 0px;
    width: 100%;
  }
}
