@import "/src/styles/variables";

.top-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  height: 100px;
  background: $app-background;
  z-index: 3;
  padding-bottom: 10px;
  padding-left: 1rem;
  padding-right: 1.5rem;
  border-radius: 4px;

  .published-top-section {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .back-to-overview {
      margin-top: 5px;
      width: 5%;
      font-size: 12px;
      cursor: pointer;
      color: $strong-blue;
      display: flex;
      text-decoration: none;

      .icon-back {
        font-size: 18px;
        //margin-top: px;
      }
    }
    .back-to-overview:hover {
      text-decoration: underline;
    }
  }

  .back-with-stepper-container {
    width: 100%;
    display: flex;
    .back-to-overview {
      margin-top: 5px;
      width: 4%;
      font-size: 12px;
      cursor: pointer;
      color: $strong-blue;
      display: flex;
      text-decoration: none;

      .icon-back {
        font-size: 18px;
        //margin-top: px;
      }
    }
    .back-to-overview:hover {
      text-decoration: underline;
    }

    .stepper-section {
      width: 100%;
    }
  }

  .profile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: $strong-blue;
    font-size: 20px;
    color: $white;
    display: grid;
    align-items: center;
    text-align: center;
    cursor: pointer;
  }
  .header-dropdown-item {
    font-size: 14px;
    margin: 0;
    cursor: pointer;
  }
  .header-dropdown-item:nth-child(2) {
    font-size: 12px;
  }

  .header-heading {
    font-size: 22px;
    margin-top: 5px;
  }

  .header-opt-btn {
    font-size: 14px;
    margin-right: 1rem;
    border: 0;
    background-color: transparent;
    color: $medium-grey;
  }

  .header-right {
    display: flex;
    align-items: center;
  }

  .header-email {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-icon-name {
    display: flex;
    align-items: center;
  }

  .header-bell-icon {
    font-size: 24px;
    cursor: pointer;
    color: $strong-blue;
  }

  .header-doc-btn {
    color: $white !important;
    padding: 0.7rem;
    padding-right: 1rem;
    margin-right: 1rem;
  }

  .header-user-icon {
    font-size: 47px;
    color: $strong-blue;
    cursor: pointer;
  }

  .header-new-btn {
    background-color: transparent;
    border: 0;
    color: $white;
  }
  .header-plus-icon {
    font-size: 30px;
    font-weight: bold;
    margin-right: 2px;
  }
  .notification-icon-container {
    margin-left: 1rem;
  }
  .notification-icon-wrapper {
    background-color: #f2f2f2;
    border-radius: 50%;
    padding: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .user-popper {
    margin-right: 2rem;
    margin-top: 0.5rem;
  }
  .notification-popper {
    margin-right: 1.5rem;
    margin-top: 0.5rem;
    padding: 1rem;
  }
  @media (max-width: 930px) {
    .user-popper {
      margin-right: 2.5rem !important;
      visibility: visible;
      .header-dropdown-item {
        font-size: 12px;
      }
    }
    .header-user-email {
      display: none;
    }

    .header-bell-icon {
      margin-left: 0;
    }

    .header-doc-btn {
      font-size: 12px !important;
    }

    .header-heading {
      font-size: 18px;
    }

    .header-opt-btn {
      font-size: 12px;
    }
  }

  @media (max-width: 540px) {
    padding-left: 0.5rem;

    .header-new-btn {
      display: none;
    }
    .user-popper {
      margin-right: 2.5rem !important;
      visibility: visible;
      .header-dropdown-item {
        font-size: 12px;
      }
    }
    .header-doc-btn {
      margin-top: -8px !important;
      align-items: center !important;
    }

    .header-email {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }

    .header-opt-btn {
      margin-right: 0.5rem;
    }
    .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
      min-width: 19px !important;
      height: 29px;
      border-radius: 50%;
    }
    .header-user-icon {
      margin-right: 0.5rem;
      font-size: 28px;
    }

    .header-bell-icon {
      font-size: 28px;
      cursor: pointer;
    }
  }
}

// notification-popper

.notification-containers {
  position: relative;
  display: inline-block;
}

.notification-badges {
  position: absolute;
  top: -10px;
  right: -8px;
  background-color: $strong-blue;
  color: $white;
  font-size: 10px;
  font-weight: bold;
  border-radius: 50%;
  padding: 0px 4px;
}

@media (max-width: 768px) {
  .top-header {
    padding-right: 0;
    width: 100vw;
  }
}
