@import "../../styles/variables";

.popper-content {
  display: flex;
  justify-content: space-around;
  padding: 10px 3px;
  border-radius: 10px;
  background-color: $white;
  align-items: center;
  .notification-container {
    margin-left: 0.5rem;
    width: 80%;
    .notification-name {
      font-size: 12px;
      margin-bottom: $spacing-base / 2;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .notification-date {
      font-size: 10px;
      color: $medium-grey;
    }
  }
  .notebook-icon-container {
    background-color: $light-pink;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    .notebook-icon {
      margin: auto;
      font-size: 12px;
    }
  }
  .notification-right-icon {
    display: flex;
    align-items: center;
  }
  @media (max-width: 1180px) {
    padding: 10px 3px;
    .notification-container {
      .notification-name {
        font-size: 12px;
      }
      .notification-date {
        font-size: 10px;
        color: $medium-grey;
      }
    }
    .notebook-icon-container {
      width: 20px;
      height: 20px;
    }
  }
}
.popper-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  .close-icon {
    border: 1px solid $placeholder-color;
    border-radius: 5px;
    padding: 3px;
    font-size: 21px;
    z-index: 10;
  }
}
