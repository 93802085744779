@import "/src/styles/variables";

status-badge {
  border: 1px solid;
  background-color: $white;
  overflow: hidden;
  font-size: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.draft {
  @extend status-badge;
  border-color: $draft-status-color;
  color: $draft-status-color;
  min-width: 5rem;
  max-width: 5rem;
  min-height: 24px;
}

.published {
  @extend status-badge;
  border-color: $published-status-color;
  color: $published-status-color;
  min-width: 6rem;
  max-width: 6rem;
  min-height: 2rem;
}

.deleted {
  @extend status-badge;
  border-color: $deleted-status-color;
  color: $deleted-status-color;
  min-width: 6rem;
  max-width: 6rem;
  min-height: 2rem;
}

.archived {
  @extend status-badge;
  border-color: $deleted-status-color;
  color: $deleted-status-color;
  min-width: 6rem;
  max-width: 6rem;
  min-height: 2rem;
}
