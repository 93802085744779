@import "src/styles/variables";

.footer-container {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 70px;
  background: $app-background;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  width: 100%;

  .sign-out-section {
    padding-right: 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: $footer-text;
    cursor: pointer;
    .pad-right {
      padding-right: 10px;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}
