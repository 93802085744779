/* style.css */
@import "../../styles/variables";

body.menu-active {
  /* Define the desired body color when the menu is active */
  //background-color: #f0f0f0;
  background-color: $app-background;
}

.header-container {
  position: sticky;
  top: 0;
  z-index: 101;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1230px;
    margin: 0 auto;
    column-gap: 2rem;
    height: 90px;
    padding-right: 0.7rem;
  }

  .unorder-list {
    list-style: none;
  }

  .link-style {
    text-decoration: none;
    color: inherit;
  }

  .icon {
    padding: 0.5rem;
    background-color: $light-grey;
    border-radius: 10px;
  }

  .logo {
    margin-right: 2rem;

    cursor: pointer;
  }

  .menu {
    position: relative;
    background-color: $app-background;
  }

  .menu-bar .dropdown-link-title {
    font-weight: 600;
  }

  .menu-bar .nav-link {
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: -0.6px;
    padding: 0.3rem;
    min-width: 60px;
    margin: 0 0.6rem;
  }

  .menu-bar .nav-link:hover,
  .dropdown-link:hover {
    color: $primary-color;
  }

  .nav-start,
  .nav-end,
  .menu-bar,
  .right-container,
  .right-container {
    display: flex;
    align-items: center;
  }

  .dropdown {
    display: flex;
    flex-direction: column;
    min-width: 230px;
    background-color: $app-background;
    position: absolute;
    top: 36px;
    visibility: hidden;
    opacity: 0;
    transform: scale(0.97) translateX(-5px);
    transition: 0.1s ease-in-out;
    box-shadow: $shadow;
    border-top: 3px solid rgb(55, 125, 255);
    border-radius: 0px 0px 8px 8px;
  }

  .dropdown.active {
    visibility: visible;
    opacity: 1;
    transform: scale(1) translateX(5px);
  }

  .dropdown ul {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.7rem;
    font-size: 0.95rem;
  }

  .dropdown-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.15rem;
    cursor: pointer;
  }

  .dropdown-link {
    display: flex;
    gap: 0.5rem;
    padding: 0.5rem 0;
    border-radius: 7px;
    transition: 0.1s ease-in-out;
  }

  .dropdown-link p {
    font-size: 0.8rem;
    color: $medium-grey;
  }

  .right-container {
    display: flex;
    align-items: center;
    column-gap: 1rem;
  }

  .right-button-container {
    text-transform: capitalize;
    margin-left: 1rem;
    background-color: $button-bg-color;
  }

  .right-button-container:hover {
    background-color: $button-bg-color;
  }

  .hamburger {
    display: none !important;
    padding-top: 0.5rem !important;
    margin-left: 1rem !important;
    font-size: 1.9rem;
    height: 40px;
    min-width: 0px !important;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    border-radius: 8px !important;
    font-size: 0.875rem;
    background-color: transparent !important;

    &:hover {
      border: 1px solid rgb(55, 125, 255) !important;
    }
  }

  .sidebar-action-btn {
    margin-right: 2rem;
    max-width: 15rem;
    margin-top: 1rem;
  }

  .sidebar-button {
    text-transform: capitalize;
    margin-left: 1rem;
    background-color: $button-bg-color;
  }

  .sidebar-button:hover {
    background-color: $button-bg-color;
  }

  @media (max-width: 1100px) {
    .hamburger {
      display: block !important;
    }

    .logo {
      margin-left: 1.5rem;
    }

    .menu {
      display: none;
      position: absolute;
      top: 87px;
      left: 0;
      min-height: 100vh;
      width: 60vw;
    }

    .dropdown {
      display: none;
      min-width: 100%;
      border: none !important;
      border-radius: 5px;
      position: static;
      top: 0;
      left: 0;
      visibility: visible;
      opacity: 1;
      transform: none;
      box-shadow: none;
    }

    .menu.show,
    .dropdown.active {
      display: block;
      z-index: 3;
    }

    .dropdown ul {
      padding-left: 0;
    }

    .menu-bar {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      row-gap: 1rem;
      padding: 1rem;
      margin-left: 0.5rem;
    }

    .menu-bar .nav-link {
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-weight: 600;
      font-size: 1.2rem;
      margin: 0;
    }

    .menu-bar li:first-child .dropdown {
      min-width: 100%;
    }

    .menu-bar > li:not(:last-child) {
      padding-bottom: 0.5rem;
      border-bottom: $border;
    }
  }

  @media (max-width: 600px) {
    .right-container {
      display: none;
    }
  }
  @media screen and (min-width: 751px) and (max-width: 900px) {
    .container {
      max-width: 710px;
    }
    .logo {
      margin-left: 1.5rem;
    }
  }
}

// Accordion css
.accordion-wrapper {
  & + * {
    margin-top: 0.5em;
  }
  .accordion-item {
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    height: auto;
    .accordion-content {
      padding: 0em 0em;
    }
  }

  .accordion-item.collapsed {
    max-height: 0;
    transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
  }
  .accordion-title {
    font-size: 14px;
    cursor: pointer;
    padding: 1em 0.5em;
    border-radius: 0.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover {
      background-color: $header-accordion-btn;
    }

    &.open {
      color: $very-dark-gray;
    }
  }
}
.subchild {
  margin-top: 5px;
  color: $button-bg;
  padding: 1em 0.5em;
  font-size: 14px;
  cursor: pointer;
  border-radius: 0.5em;
  &:hover {
    background-color: $header-accordion-btn;
  }
}
.subchild-link {
  text-decoration: none;
  color: $very-dark-gray;
}
