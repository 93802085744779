@import "src/styles/variables";

.share-document-modal {
  .help-box-body {
    padding: 1rem 1.5rem 1.5rem 1.5rem;
    .input-fields {
      margin-top: 20px;

      .tag-container {
        height: min-content;
        min-height: 120px;
        border: 1px solid hsl(0, 0%, 80%);
        width: 100%;
        padding: 10px;
        background-color: $white;

        .tag-icon {
          font-size: 15px;
        }

        .chip-gap {
          margin-left: 5px;
          margin-top: 5px;
        }
      }

      .bottom-button-section {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
      }

      .label-style {
        font-size: 0.875rem;
      }

      .padd-top {
        margin-top: 22px;
        .container-calender {
          margin-top: 10px;
          .label-style {
            margin-top: 22px;
          }
          .calender {
            padding: 0.5rem;
            width: 100%;
            border: 1px solid hsl(0, 0%, 80%);
            border-radius: 3px;
          }
          .react-datepicker-wrapper {
            width: 100%;
          }
        }
      }

      // React-select
      .css-13cymwt-control {
        min-height: 52px;
        border-radius: 4px;
        border-style: solid;
        border-width: 1px;
        box-sizing: border-box;
      }

      .next-button {
        margin-top: 20px;
        height: 36.5px;
        min-width: 75px;
      }
      .css-t3ipsp-control {
        min-height: 52px;
      }
    }
    .help-box-subheading {
      font-size: 14px;
    }
  }
  .help-box-bottom {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
    .help-box-submit {
      padding: 8px 16px !important;
      color: $white !important;
      cursor: pointer !important;
      margin-right: 0.5rem;
      .submit-icon {
        margin-right: 5px;
      }
    }
    .help-box-cancel {
      background-color: $white !important;
      padding: 8px 16px !important;
      color: $button-bg-color !important;
      cursor: pointer !important;
      border: 1px solid $button-bg-color !important;
      margin-right: 1rem !important;
      .cancel-icon {
        margin-right: 5px;
      }
    }
  }
  @media (max-width: 500px) {
    .help-box-heading {
      font-size: 13px;
      padding: 1rem;
    }
    .help-box-body {
      padding: 1rem;
      .help-box-subheading {
        font-size: 12px;
      }
    }
  }
}
