@import "../../styles/variables";

.search-container {
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  background-color: $container-white-background;
  padding: 1rem;
  box-shadow: rgba(140, 152, 164, 0.176) 0px 10px 40px 10px;

  .search-icon-svg {
    width: 24px;
    height: 24px;
    color: $strong-blue;
    font-size: 1.2rem;
    margin-right: 0.5rem;
    margin-left: 1rem;
  }

  .search-input-box {
    flex: 1;
    height: 100%;
    padding: 0.5rem;
    border: none;
    border-radius: 3px;
    font-size: 1rem;
    background-color: transparent;
    outline: none;
    font: inherit;
    padding: 16.5px 14px 16.5px 0px;
    border-radius: 5px;
  }

  .search-result {
    margin: 0;
    font-size: 0.9rem;
    color: $form-caption-color;
    margin-right: 1rem;
  }

  .search-button {
    height: 40px;
    height: 54px;
    width: 100px;
    text-transform: capitalize;
    background-color: $button-bg-color;
  }

  .search-button:hover {
    background-color: $button-bg-color;
  }

  .search-input-box::placeholder {
    color: $placeholder-color;
  }
}

@media (max-width: 600px) {
  .search-icon-svg {
    margin-left: 0;
  }

  .search-result {
    display: none;
  }

  .search-button {
    padding: 0.75rem 1rem;
  }
  .search-input-box {
    width: 80%;
  }
}
@media (max-width: 430px) {
  .search-input-box {
    width: 50%;
  }
}
