@import "/src/styles/variables";

.deleted-top-header-container {
  width: 100%;
  display: flex;

  .left-deleted-sec {
    display: flex;
    align-items: center;
    width: 21%;

    .fc-doc {
      font-size: 65px;
    }
    .doc-with-status {
      display: flex;
      flex-direction: column;
      width: 100%;

      .chip-gap {
        width: 50%;
        background-color: $white;
      }
    }
  }
  .center-deleted-sec {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    .doc-name {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: 22px;
    }
  }
}
