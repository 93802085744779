@import "../../styles/variables";

.main-layout-container {
  background-color: $app-background;
  height: 100vh;

  .home {
    position: absolute;
    top: 0;
    left: 250px;
    width: calc(100% - 250px);
    background-color: $app-background;
    transition: $tran-05;
    padding-left: 10px;
  }

  .without-left-navbar {
    width: 100%;
    position: absolute;
    left: 0;
  }
}

@media only screen and (max-width: 780px) {
  .main-layout-container {
    .home {
      left: 0;
    }
  }
}
