.popper-container {
  position: absolute;
  z-index: 100;
  right: 0;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 0.5rem;
  margin-top: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-right: 0.5rem;
  transition: 0.5s;

  ::before {
    content: "";
    position: absolute;
    top: -5px;
    right: 10px;
    width: 20px;
    height: 20px;
    background-color: #ffffff;
    transform: rotate(45deg);
  }
}
