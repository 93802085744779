@import "../../styles/variables";

.header-input-box {
  &__input {
    border: 0px solid $input-border-color;
    border-bottom: 1px solid $input-border-color;
    height: 2.3rem;
    border-radius: 5px;
    padding: 1rem;
    font-size: 1.7rem;
    background-color: transparent;
    text-align: center;

    &:focus {
      outline: none;
      border-color: $strong-blue;
    }
  }
}
