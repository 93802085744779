.loading-overlay-container {
  display: flex;
  background: rgba(255, 255, 255, 0.5);
  position: fixed;
  width: 100%;
  left: 0;
  height: 100vh;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  .loading-content {
    .loader-sec {
      margin-left: 60px;
    }
    display: flex;
    flex-direction: column;
  }
}
