@import "src/styles/variables";

.block-box-container {
  .block-box-body {
    padding: 1rem 1.5rem 1.5rem 1.5rem;
  }
  .block-box-bottom {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
    .block-box-submit {
      padding: 8px 16px !important;
      color: $white !important;
      cursor: pointer !important;
      margin-right: 0.5rem;
      .submit-icon {
        margin-right: 5px;
      }
    }
    .block-box-cancel {
      background-color: $white !important;
      padding: 8px 16px !important;
      color: $button-bg-color !important;
      cursor: pointer !important;
      border: 1px solid $button-bg-color !important;
      margin-right: 1rem !important;
      .cancel-icon {
        margin-right: 5px;
      }
    }
  }
}
