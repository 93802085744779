@import "src/styles/variables";

.button-container {
  text-transform: capitalize !important;
  font-family: $mui-font-family !important;
}

button:disabled,
button[disabled] {
  border: 1px solid $disable-button-border !important;
  background-color: $disable-button !important;
  color: $disable-button-text-color !important;
}
