@import "../../styles/variables";

.search-container {
  height: 25px !important;

  .css-1le28he-MuiInputBase-root {
    font-family: "Poppins";
  }

  .search-input-container {
    position: relative;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.15);
    &:hover {
      background-color: rgba(255, 255, 255, 0.25);
    }
    width: 100%;
    @media (min-width: 600px) {
      width: auto;
    }
  }

  .search-icon-wrapper {
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 600px) {
    .search-icon-wrapper {
      display: none !important;
    }
  }

  .styled-input-base {
    color: inherit;
    & .MuiInputBase-input {
      padding-left: calc(1em + 32px);
      transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      width: 100%;
      @media (min-width: 960px) {
        width: 200px;
      }
      @media (max-width: 600px) {
        padding-left: 0;
      }
    }
  }
}
