@import "../../styles/variables";

.curved-down {
  height: 255px;
  background-color: $curved-background;
  top: 0;
  margin-top: -10px;
}
.curved-svg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  z-index: 2;
  width: 100%;
}

.curved-down-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
}

@media (min-width: 0px) {
  .headingH4 {
    font-size: 1.5625rem;
  }
}
@media (min-width: 600px) {
  .headingH4 {
    font-size: 1.8219rem;
  }
}

@media (min-width: 900px) {
  .headingH4 {
    font-size: 2.0243rem;
  }
  .formContainer {
    margin-top: -5rem;
  }
}

.headingH4 {
  margin: 0px;
  line-height: 1.235;
  font-weight: 600 !important;
}

.description-text {
  margin: 0;
  font-weight: 400 !important;
  font-size: 1.125rem;
  line-height: 1.6;
  text-align: center;
  color: $form-caption-color;
  max-width: 90%;

  @media (min-width: 600px) {
    font-size: 1.25rem;
  }

  @media (min-width: 900px) {
    font-size: 1.25rem;
  }
}

.rating-section {
  margin-top: 1rem;
}
