@import "../../../src/styles/variables";

.ship-card {
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 4px;
  transition: transform 0.5s;
  cursor: pointer;
  &:hover {
    transform: translateY(-5px);
  }
  .flag {
    position: absolute;
    top: 0;
    right: 0;
    .flag-img {
      height: auto;
      margin-top: -5px;
      margin-left: 5px;
      width: 100%;
    }
  }
  .ship-card-img {
    height: 158px;
    display: block;
    border: 0 none;
    border-radius: 4px;
    width: 100%;
  }
  .ship-name {
    font-size: 18px;
    color: $ship-card-heading;
    margin-bottom: 1rem;
    padding-left: 10px;
    margin-top: 10px;
  }

  .card-ship-ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 14px;
    padding: 0 10px 0 0;
    line-height: 1.4;
    padding-left: 10px;
    text-align: left;
    .destination-name {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 5px;
      .card-ship-strong {
        font-weight: 600;
        margin-right: 5px;
      }
      .destinations-name {
        text-decoration: none;
        color: $ship-card-heading;

        &:hover {
          text-decoration: underline;
        }
      }
    }
    .ship-port-name {
      width: 100%;
      margin-bottom: 8px;
      .card-ship-strong {
        font-weight: 600;
        margin-right: 5px;
      }
      .ship-port-name {
        text-decoration: none;
        color: $ship-card-heading;

        &:hover {
          text-decoration: underline;
        }
      }
    }
    .ship-port-duration {
      margin-bottom: 5px;
      .ship-duration-strong {
        font-weight: 600;
        margin-right: 5px;
      }
      .trip-length-name {
        text-decoration: none;
        color: $ship-card-heading;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
