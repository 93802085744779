@import "src/styles/variables";

.recent-update-container {
  width: 100%;
  margin-top: 4px;

  &__list {
    display: flex;
    flex-direction: column;
    gap: $spacing-small;
  }

  .recent-update-heading {
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    .recent-heading {
      color: $dark-grey;
    }
    .recent-view-more {
      font-size: 10px;
      color: $color-blue;
      text-decoration: underline;
      font-weight: 500;
      display: grid;
      align-items: center;
      cursor: pointer;
    }
  }
  @media (max-width: 1230px) {
    .recent-update-heading {
      .recent-heading {
        font-size: 10px;
      }
      .recent-view-more {
        font-size: 8px;
      }
    }
  }
}
