@import "/src/styles/variables";

.user-profile-container {
  cursor: pointer;
  padding-left: 0.5rem;
  .user-profile {
    display: flex;

    .profile-image {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: $strong-blue;
      font-size: 25px;
      color: $white;
      display: grid;
      align-items: center;
      text-align: center;
      letter-spacing: 3px;
      margin-top: 0.4rem;
    }
    .header-name-email {
      padding: 0.5rem;
      margin-left: 5px;
      margin-top: 0.4rem;
      .header-user-name {
        font-size: 14px;
      }
      .header-user-email {
        font-size: 12px;
        color: $medium-grey;
      }
      .header-role {
        margin-top: 0.2rem;
        font-size: 14px;
      }
    }
  }
  .manage-bottom-container {
    margin-top: 1.5rem;
    .manage-account-btn {
      background-color: $white;
      padding: 0.2rem 0.5rem 0.2rem 0.5rem;
      border: 1px solid $table-header;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}
