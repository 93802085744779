@import "/src/styles/variables";

.hamburger-menu {
  display: none;
  cursor: pointer;
}

.hamburger-menu-item {
  position: fixed;
  left: 0;
  width: 90%;
  height: 100%;
  z-index: 1000;
  background-color: $app-background;
}

.publish-top-header-container {
  width: 100%;
  display: flex;

  .left-publish-sec {
    display: flex;
    align-items: center;
    width: 21%;

    .fc-doc {
      font-size: 65px;
    }
    .doc-with-status {
      display: flex;
      flex-direction: column;
      width: 100%;

      .chip-gap {
        width: 50%;
        background-color: $white;
      }
    }
  }
  .center-publish-sec {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    .doc-name {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: 22px;
    }
  }
  .right-publish-sec {
    width: 30%;
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 10px;
    .button-sec-class {
      font-size: 12px;
    }
    .download-button {
      width: 25%;
      background-color: $white;
    }

    .more-info-popper {
      margin-right: 1.5rem;
      margin-top: 12rem;
    }

    .doc-info-container {
      width: 200px;
      height: 130px;

      .chip-gap {
        margin-top: 10px;
      }
    }
  }
  .css-1dsq9pb-MuiChip-root {
    background-color: $white;
    border: 1px solid $strong-blue;
    color: $strong-blue;
  }
}

@media (max-width: 768px) {
  .hamburger-menu {
    display: block;
    cursor: pointer;
  }
  .publish-top-header-container {
    flex-direction: column;
    margin-top: 5rem;
    background-color: $app-background;
    padding-bottom: 0.5rem;

    .left-publish-sec {
      width: 100%;
    }

    .center-publish-sec {
      width: 100%;
      justify-content: flex-start;
      padding-left: 1rem;
    }

    .right-publish-sec {
      width: 100%;
      justify-content: flex-start;
      padding-left: 1rem;
      margin-top: 1rem;
    }
  }
}
