@import "../../styles/variables";

.separator-parents {
  background-color: $separator-color;
  padding: 1rem;
  min-height: 3rem;
  .separator-box {
    color: $white;
  }
  .separator-name {
    color: $white;
    cursor: pointer;
    font-size: 12px;
  }
}

.current-page-item {
  color: $white;
  font-size: 12px;
  margin-top: 4px;
}

.separator-name:hover {
  text-decoration: underline;
}

@media (min-width: 0px) {
  .presentation {
    margin: auto;
    padding-left: 15px;
  }
}

@media (min-width: 600px) {
  .presentation {
    max-width: 720px;
  }
}

@media (min-width: 900px) {
  .presentation {
    max-width: 1236px;
  }
}
