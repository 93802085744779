@import "src/styles/variables";

.help-box {
  .help-box-body {
    padding: 1rem 1.5rem 1.5rem 1.5rem;
    .help-box-textarea {
      margin-top: 1rem;
      width: 100%;
      padding: 10px;
      font-size: 14px;
      border: 1px solid$placeholder-color;
      border-radius: 4px;
      resize: vertical;
      &:focus {
        outline: none;
        border: 2px solid $strong-blue;
      }
    }
    .help-box-subheading {
      font-size: 14px;
    }
  }
  .help-box-bottom {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
    .help-box-submit {
      padding: 8px 16px !important;
      color: $white !important;
      cursor: pointer !important;
      margin-right: 0.5rem;
      .submit-icon {
        margin-right: 5px;
      }
    }
    .help-box-cancel {
      background-color: $white !important;
      padding: 8px 16px !important;
      color: $button-bg-color !important;
      cursor: pointer !important;
      border: 1px solid $button-bg-color !important;
      margin-right: 1rem !important;
      .cancel-icon {
        margin-right: 5px;
      }
    }
  }
  @media (max-width: 500px) {
    .help-box-heading {
      font-size: 13px;
      padding: 1rem;
    }
    .help-box-body {
      padding: 1rem;
      .help-box-subheading {
        font-size: 12px;
      }
    }
  }
}
