@import "/src/styles/variables";

.draft-Top-Header {
  width: 100%;
  display: flex;

  .left-draft-section {
    display: flex;
    align-items: center;
    width: 13%;

    .fc-doc {
      font-size: 65px;
    }
    .doc-with-status {
      display: flex;
      flex-direction: column;
      width: 100%;
      .doc-name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .css-1be8v12-MuiChip-root {
        font-family: "Poppins", sans-serif !important;
      }
      .chip-gap {
        background-color: $white;
        min-width: 5rem;
        max-width: 5rem;
      }
    }
  }
  .center-draft-section {
    width: 74%;
    margin-right: 45px;
    margin-top: 5px;
  }
  .right-draft-section {
    width: 13%;
    display: flex;
    justify-content: right;
    align-items: center;
    .ship-icon {
      margin-right: 8px;
      font-size: 20px;
      color: $strong-blue;
    }
    .css-1dsq9pb-MuiChip-root {
      background-color: $white;
      border: 1px solid $strong-blue;
      color: $strong-blue;
    }
    .css-6od3lo-MuiChip-label {
      padding-left: 0;
    }
  }
}
